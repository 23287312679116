<template>
  <div>
    <a-form-model
      ref="sectionEditForm"
      :model="form"
      :rules="rules"
    >
      <a-tabs>
        <a-tab-pane v-for="language in this.langs" :key="language.id" :tab="language.desc">
          <a-form-model-item label="Name" :prop="`localizations.${form.localizations.findIndex(localization => localization.lang_id == language.id).toString()}.title`" :rules="[{ required: true, message: `This field is required`, trigger: 'change', },]">
            <a-input @change="(e) => onLocalizationChange(form.localizations.findIndex(localization => localization.lang_id == language.id), e.target.value)" :value="form.localizations.find(localization => localization.lang_id == language.id)?.title" :placeholder="`${language.desc} name`"/>
          </a-form-model-item>
        </a-tab-pane>
      </a-tabs>
      <a-form-model-item ref="type_id" label="Select image size" prop="type_id" class="mb-3" v-if="this.sectionObj?.type.name !== 'matches'">
        <a-radio-group v-model="form.type_id" size="default" class="w-100">
          <a-radio-button
            :value="1"
            class="width-33p text-center"
            :disabled="sectionObj && sectionObj.records && sectionObj.records.length > 0 && sectionObj.type.name !== 'links_small'"
          >
            Small
          </a-radio-button>
          <a-radio-button
            :value="2"
            class="width-33p text-center"
            :disabled="sectionObj && sectionObj.records && sectionObj.records.length > 0 && sectionObj.type.name !== 'links_medium'"
          >
            Medium
          </a-radio-button>
          <a-radio-button
            :value="3"
            class="width-33p text-center"
            :disabled="sectionObj && sectionObj.records && sectionObj.records.length > 0 && sectionObj.type.name !== 'links_large'"
          >
            Large
          </a-radio-button>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item ref="is_active" prop="is_active">
        <a-switch
          v-model="form.is_active"
          :disabled="!disableSwitch"
        />
        <span class="ml-2">{{statusIsActive}}</span>
      </a-form-model-item>
      <hr class="hr-bottom">
      <a-row>
        <a-col class="text-left" :span="6">
          <span v-if="sectionObj">
            <a-popconfirm
              title="Sure to delete?"
              @confirm="deleteSection"
            >
              <a href="javascript:;">
                <a-button type="danger" :loading="deleteLoading">Delete</a-button>
              </a>
            </a-popconfirm>
          </span>
        </a-col>
        <a-col class="text-right" :span="18">
          <a-button @click="closeModal">
            Cancel
          </a-button>
          <a-button v-if="sectionObj" :loading="submitLoading" type="primary" @click="onSubmit" class="ml-2">
            Update
          </a-button>
          <a-button v-else :loading="submitLoading" type="primary" @click="onSubmit" class="ml-2">
            Create
          </a-button>
        </a-col>
      </a-row>
    </a-form-model>
  </div>
</template>
<script>
import TopService from '@/services/api/apiTopService'

export default {
  name: 'sectionModal',
  props: ['sectionObj', 'langs', 'activeTabId'],
  components: {},
  data() {
    const initialLocalizations = this.langs.map(lang => ({
      lang_id: lang.id,
      title: this.sectionObj ? this.sectionObj.localizations.find(el => el.lang_id === lang.id)?.title || '' : '',
    }))
    return {
      submitLoading: false,
      deleteLoading: false,
      form: {
        type_id: this.sectionObj ? this.sectionObj.type.id : 2,
        weight: this.sectionObj ? this.sectionObj.weight : 0,
        is_active: this.sectionObj ? this.sectionObj.is_active : false,
        localizations: initialLocalizations,
        tab_id: this.activeTabId,
      },
      rules: {},
    }
  },
  computed: {
    statusIsActive() {
      return this.form.is_active ? 'Active' : 'Inactive'
    },
    disableSwitch() {
      return !!(this.sectionObj?.records?.find(record => record.is_active) || this.sectionObj?.type.name === 'matches')
    },
  },
  methods: {
    onLocalizationChange(id, title) {
      this.form.localizations[id].title = title
    },
    closeModal() {
      this.$emit('closeSectionModal')
    },
    submitCreateModal(createdRecord) {
      this.$emit('createSection', createdRecord)
    },
    submitEditModal(updatedRecord) {
      this.$emit('updateSection', updatedRecord)
    },
    submitDeleteModal(deletedId) {
      this.$emit('deleteSection', deletedId)
    },
    onSubmit() {
      this.$refs.sectionEditForm.validate(valid => {
        if (valid) {
          if (this.sectionObj) {
            this.updateSection()
          } else {
            this.createSection()
          }
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    async createSection() {
      this.submitLoading = true
      return TopService.createSection(this.form).then((response) => {
        this.submitLoading = false
        const created = response.data.data
        this.$notification.success({
          message: 'New section created',
          description: '',
        })
        this.submitCreateModal(created)
      }).catch(error => {
        console.log(error)
        this.submitLoading = false
        this.$notification.error({
          message: 'Error while creating section',
          description: error.message,
        })
      })
    },
    async updateSection() {
      this.submitLoading = true
      return TopService.updateSection(this.sectionObj.id, this.form).then((response) => {
        this.submitLoading = false
        const updated = response.data.data
        this.$notification.success({
          message: 'Section updated',
          description: '',
        })
        this.submitEditModal(updated)
      }).catch(error => {
        console.log(error)
        this.submitLoading = false
        this.$notification.error({
          message: 'Error while updating section',
          description: error.message,
        })
      })
    },
    async deleteSection() {
      this.deleteLoading = true
      return TopService.deleteSection(this.sectionObj.id).then((response) => {
        this.$notification.success({
          message: 'Section successfully deleted',
          description: '',
        })
        this.deleteLoading = false
        this.submitDeleteModal(this.sectionObj.id)
      }).catch(error => {
        this.deleteLoading = false
        this.$notification.error({
          message: 'Error while deleting section',
          description: error.message,
        })
      })
    },
  },
}
</script>
<style scoped>
.ant-form-item-label {
  padding: 0px !important;
}
.link-image {
  margin-bottom: 20px;
  display: inline-block;
}
.hr-bottom {
  margin-left: -24px;
  margin-right: -24px;
}
</style>
